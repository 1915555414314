import { render, staticRenderFns } from "./LoadingComponent.vue?vue&type=template&id=16eb2cc8"
var script = {}
import style0 from "./LoadingComponent.vue?vue&type=style&index=0&id=16eb2cc8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports