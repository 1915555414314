<template>
  <v-snackbar v-model="show" :timeout="timeout">
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn color="blue" text v-bind="attrs" @click="show = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: true
    };
  },
  props: {
    message: {
      required: true,
      type: String
    },
    timeout: {
      default: 3000,
      type: Number
    }
  }
  /// NEEDS A METHOD THAT CLEARS THE MESSAGE / SNACK FROM THE STORE!
};
</script>
