import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import Settings from "../settings";
import store from "../store";

Vue.use(VueRouter);

function isLoggedIn() {
  // checks for login token and whether it is timed out
  return store.getters.loggedIn;
  // if (
  //   localStorage.getItem("token_type") &&
  //   localStorage.getItem("access_token")
  // ) {
  //   if (
  //     Date.now() - localStorage.getItem("loginTime") >
  //     Settings.loginTokenDuration
  //   ) {
  //     console.log("login timeout... ");
  //     logout("Login timeout, please login again");
  //     return false;
  //   } else {
  //     console.log("loggged in; " + localStorage.getItem("access_token"));
  //     return true;
  //   }
  // } else {
  //   console.log("not logged in...");
  //   //logout("You are not logged in!");
  //   return false;
  // }
}

function logout() {
  store.commit("logout");
  // localStorage.setItem("access_token", "");
  // localStorage.setItem("token_type", "");
  // localStorage.setItem("loginTime", 0);
  router.push({ name: "login", params: { message: "logged out" } });
}

export { logout, isLoggedIn };

const routes = [
  {
    path: "/",
    name: "Home",
    title: "Grimm-Müller: Porzellan freigedreht",
    meta: "",
    component: Home
  },
  {
    path: "/admin",
    name: "Admin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "text",
        name: "AdminText",
        component: () =>
          import(
            /* webpackChunkName: "AdminText" */ "@/views/admin/adminTexts.vue"
          )
      },
      {
        path: "markets",
        name: "AdminMarkets",
        component: () =>
          import(
            /* webpackChunkName: "AdminText" */ "../views/admin/adminMarkets.vue"
          )
      },
      {
        path: "profile",
        name: "AdminProfile",
        component: () =>
          import(
            /* webpackChunkName: "AdminText" */ "../views/admin/profile.vue"
          )
      },
      {
        path: "pics",
        name: "AdminPics",
        component: () =>
          import(
            /* webpackChunkName: "AdminPics" */ "../views/admin/adminImages.vue"
          )
      },
      {
        path: "notification",
        name: "AdminNotification",
        component: () =>
          import(
            /* webpackChunkName: "AdminPics" */ "../views/admin/adminNotification.vue"
          )
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/*webpackChunkName: "login" */ "../views/Login")
  },
  {
    path: "/404",
    alias: "*",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../components/NotFound")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      const position = {};
      if (to.hash) {
        position.selector = to.hash;
        if (document.querySelector(to.hash)) {
          position.offset = { y: 45 };
          return position;
        }
        return false;
      }
      return;
    }
  }
});

// navigation guards (needed for login:)
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log("need for Logged In");
    // need to login!
    if (!isLoggedIn()) {
      next({
        name: "login"
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
