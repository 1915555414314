<template>
  <div>
    <v-app-bar
      app
      color="grey darken-1"
      :id="[$vuetify.breakpoint.mobile ? 'nav_mobile' : 'nav']"
    >
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mobile"
        @click.stop="drawer = !drawer"
        solid
      >
      </v-app-bar-nav-icon>

      <span v-if="$vuetify.breakpoint.mobile">
        Grimm-Müller
      </span>

      <ul
        v-if="!$vuetify.breakpoint.mobile"
        :class="[$vuetify.breakpoint.mobile ? 'mobileNav' : 'nav-links']"
        @click="mobileMenu = false"
      >
        <li class="links" v-for="l in links" :key="l.text">
          <v-btn text color="dark-grey" :to="{ name: 'Home', hash: l.hash }">{{
            l.text
          }}</v-btn>
        </li>
        <!-- NOTIFICATIONS -->
        <li v-if="!$vuetify.breakpoint.mobile">     
          <notification />
        </li>
      </ul>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      absolute
      style="position:fixed;"
      
    >
      <v-list nav dense>
        <v-list-item
          v-for="l in links"
          :key="l.text"
          :to="{ name: 'Home', hash: l.hash }"
        >
          {{ l.text }}
        </v-list-item>
        <!-- Notification -->
        <v-list-item v-if="$vuetify.breakpoint.mobile">    
            <notification />
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import notification from './notification.vue';
export default {
  components: { notification },
  name: "NavBar",
  data() {
    return {
      mobileMenu: false,
      drawer: false,
      links: [
        {
          text: "Start",
          hash: "#header"
        },
        {
          text: "Hanna Grimm-Müller",
          hash: "#about"
        },
        {
          text: "Märkte",
          hash: "#maerkte"
        },
        {
          text: "Porzellan",
          hash: "#info"
        },
        {
          text: "Galerie",
          hash: "#gallery"
        },
        {
          text: "Kontakt",
          hash: "#footer"
        }
      ]
    };
  },
  mounted() {
    console.log(this.$vuetify.breakpoint.width);
    console.log("mobile:" + this.$vuetify.breakpoint.mobile);
  },
  methods: {}
};
</script>

<style scoped>
#nav {
  display: flex;
  justify-content: center;
  background: darkgray;
}

.nav-links {
  display: flex;
  list-style: none;
  text-decoration: none;
}

.links:hover {
  text-decoration: underline;
}

#nav a {
  color: #ffffff;
  padding: 0 10px;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #ca0000;
  text-decoration: none;
}

.v-toolbar__content {
  left: 0px;
}
</style>
