<template>
  <v-app>
    <NavBar />
    <v-main id="mainFrame">
      <ErrorMessage
        v-for="(e, index) in getAllErrors"
        :key="index"
        :msg="e"
        :index="index"
      />
      <router-view :key="$route.path" />
    </v-main>

    <GoodNews
      v-if="allTheGoodNews"
      :message="allTheGoodNews.message"
      :timeout="allTheGoodNews.timeout"
    />
    <!-- LOADING all over the window -->
    <v-dialog v-model="isLoading" hide-overlay persistent width="200">
      <v-card>
        <loading-component />
      </v-card>
    </v-dialog>

    <the-footer />
  </v-app>
</template>

<script>
//import HelloWorld from './components/HelloWorld';
import NavBar from "@/components/NavBar";
import theFooter from "@/components/theFooter";
import ErrorMessage from "./components/core/ErrorMessage.vue";
import GoodNews from "./components/core/GoodNews.vue";
import LoadingComponent from "./components/core/LoadingComponent.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    NavBar,
    theFooter,
    ErrorMessage,
    LoadingComponent,
    GoodNews
  },
  data() {
    return {
      title: "Grimm-Müller: Porzellan freigedreht",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Hanna Grimm-Müller fertigt in Handarbeit Teller, Tassen und Vasen aus Porzellan. Die Keramik ist zum täglichen Gebrauch gedacht und wird hauptsächlich über Kunsthandwerkermärkte vertrieben."
        },
        {
          vmid: "keywords",
          name: "keywords",
          content:
            "Porzellan, freigedreht, Keramik, Kunsthandwerk, handgemacht, Teller, Tasse, Tassen, Vase, Blumenvase, Kerzenständer, Handwerk, Kunst, handbemalt, Töpferei, Ton, gedreht, Drehscheibe, Verkauf, spülmaschienenfest, Kunsthandwerkermarkt,"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getAllErrors", "allTheGoodNews", "isLoading"])
  },
  mounted() {
    this.$store.dispatch("getPictures");
  }
};
</script>

<style>
/* #mainFrame {
  max-width:1000px;
} */
.container {
  padding: 40px 0 !important;
}

@media screen and (min-width: 601px) {
  h2.responsive {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 600px) {
  h2.responsive {
    font-size: 35px !important;
  }
}
</style>
