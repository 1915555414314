<template>
  <section class="section" id="maerkte">
    <v-parallax :src="imageHost + 'markt1.jpg'" height="500">
      <v-container fill-height justify="center">
        <h2 class="responsive">Märkte</h2>
        <div align="center" justify="center" class="overflow-x-auto">
          <!-- <v-col
                cols="4"
                md="4"
                sm="4"
              > -->
          <error-message v-if="error" :error="error" />

          <v-card flat v-for="markt in marktTabelle" :key="markt.id">
            <v-card-title class="marktDate">
              <span>{{ markt.start_date | formatDate }}</span>
              <span v-if="markt.end_date"> - </span>
              <span v-if="markt.end_date">
                {{ markt.end_date | formatDate }}
              </span>
            </v-card-title>

            <v-card-text class="martkNamen">
              {{ markt.market_name }}
            </v-card-text>

            <v-card-actions>
              <v-btn
                v-if="markt.infotext"
                text
                color="dark-grey"
                @click="reveal = markt.id"
              >
                mehr...
              </v-btn>
              <v-btn
                class="marktLinkBtn"
                v-if="markt.link"
                :href="markt.link"
                target="_blank"
              >
                Markt website
              </v-btn>
            </v-card-actions>

            <!-- "back-side" of the card: -->

            <v-expand-transition>
              <v-card
                v-if="reveal === markt.id"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
              >
                <v-card-subtitle class="marktDate">
                  {{ markt.market_name }}
                </v-card-subtitle>

                <v-card-text class="pb-0">
                  <p>{{ markt.infotext }}</p>
                </v-card-text>
                <!-- <v-btn
                  class="marktLinkBtn"
                  v-if="markt.link"
                  :href="markt.link"
                  target="_blank"
                >
                  Markt website
                </v-btn> -->
                <v-card-actions class="pt-0">
                  <v-btn text color="dark-grey" @click="reveal = 0">
                    zurück
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>

          <v-card v-if="!marktTabelle.length && !error">
            <v-card-title>
              Zur Zeit sind keine Märkte geplant.
            </v-card-title>
          </v-card>
          <!-- </v-col> -->
        </div>
      </v-container>
    </v-parallax>
  </section>
</template>

<script>
import moment from "moment";
import Settings from "@/settings.js";
import utils from "@/utils.js";
import errorMessage from "../components/errorMessage.vue";

export default {
  components: { errorMessage },
  name: "maerkte-overview",
  data() {
    return {
      imageHost: Settings.imageHost,
      reveal: 0,
      marktImage: {},
      marktTabelle: [],
      error: false
    };
  },
  props: {},
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD.MM.YYYY");
      }
    }
  },
  methods: {
    async getMarketData() {
      try {
        this.marktTabelle = await utils.loadMarkets();
      } catch (e) {
        console.log(e);
        this.error = "Markttermine können zur Zeit nicht geladen werden.";
      }
      // sort by date:
      this.marktTabelle.sort((a, b) => {
        return new Date(a.start_date) - new Date(b.start_date);
      });
    }
  },
  mounted() {
    this.getMarketData();
    this.marktImage = utils.loadImage("markt");
  }
};
</script>

<style scoped>
.v-card {
  background-color: rgb(255, 255, 255, 0.65) !important;
  color: black !important;
  height: 250px;
  width: 250px !important;
  padding: 20px;
  margin: 10px 20px;
  flex: 0 0 200px;
}

.v-card--reveal {
  background-color: rgb(255, 255, 255, 1) !important;
  left: -5px;
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

h2 {
  width: 100%;
  text-align: center;
}

.overflow-x-auto {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  overflow-y: hidden;
  flex-direction: row;
}

.marktDate {
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
}

.martkNamen {
  display: inline-block;
  color: black;
  font-size: 20px;
  text-align: left;
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

/* scrollbar styles for edge, safari, chrome */
/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px darkgray;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ca0000;
  border-radius: 10px;
}
</style>
